import { Image, Col } from 'antd';
import { IModalProps, IModalStyleProps, IModalStyles, IStyleFunctionOrObject } from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import { Dialog, DialogType } from '@fluentui/react/lib/Dialog';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import './dialog.style.scss';

interface DialogComponentProps {
  className?: string;
  dialogContentProps?: dialogContentProps;
  buttonText?: React.ReactNode;
  children: React.ReactNode;
  onDismiss?: () => void;
  isVisible: boolean;
}

const dialogStyles: IStyleFunctionOrObject<IModalStyleProps, IModalStyles> = {
  root: {},
  main: {
    width: 460,
    '@media(max-width: 576px)': {
      background: 'transparent',
      height: '100%',
    },
    height: 527,
    borderRadius: 15,
  },
  scrollableContent: {
    '>div': {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    '.ms-Button': {
      'z-index': '1',
    },
    '.ms-Dialog-inner': {
      padding: '0',
      height: '100%',
      marginLeft: '16px',
      marginRight: '16px',
      display: 'flex',
      flexGrow: '1',
    },
  },
};

interface dialogContentProps {
  showCloseButton?: boolean;
  type?: DialogType;
  title?: string;
  closeButtonAriaLabel?: string;
  subText?: string;
}

const DialogOpenComponent: React.FC<DialogComponentProps> = ({
  dialogContentProps,
  children,
  onDismiss,
  isVisible,
  className,
}) => {
  const labelId: string = useId('dialogLabel');
  const subTextId: string = useId('subTextLabel');

  const modalProps: IModalProps = useMemo(
    () => ({
      className: className,
      containerClassName: 'dialog-container',
      scrollableContentClassName: 'dialog-scrollable-content',
      titleAriaId: labelId,
      subtitleAriaId: subTextId,
      isBlocking: true,
      styles: dialogStyles,
    }),
    [labelId, subTextId],
  );
  return (
    <Dialog
      hidden={!isVisible}
      onDismiss={onDismiss}
      dialogContentProps={dialogContentProps}
      modalProps={modalProps}
      maxWidth={460}
      minWidth={460}
    >
      <StyledLogoWrapper sm={0}>
        <Image preview={false} src={'/fastrentlogo.svg'} width={104} alt="logo" />
      </StyledLogoWrapper>
      <StyledMain>{children}</StyledMain>
    </Dialog>
  );
};
export default DialogOpenComponent;

const StyledLogoWrapper = styled(Col)`
  position: relative;
  text-align: center;
  width: 100%;
  top: -15px;
`;

const StyledMain = styled.main`
  height: 470px;
  > div {
    margin-top: 8px;
    height: 100%;
  }
`;
