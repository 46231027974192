import React, { useState, useEffect } from 'react';
import { Car } from '../utils/types';
import moment, { Moment } from 'moment';

export type AppContextType = {
  selectedCar: Car | null;
  setSelectedCar: (car: Car | null) => void;
  rentalFromDate: Moment | null;
  setRentalFromDate: (from: Moment | null) => void;
  rentalUntilDate: Moment | null;
  setRentalUntilDate: (from: Moment | null) => void;
  rentalLocation: string | null;
  setRentalLocation: (location: string | null) => void;
  loginMethod: string | null;
  setLoginMethod: (loginMethod: string | null) => void;
  userEmail: string | null;
  setUserEmail: (userEmail: string | null) => void;
};

const contextStorageKeyName = 'app-context';
function getInitialContext(): AppContextType {
  const strContext = localStorage.getItem(contextStorageKeyName);
  const context = strContext ? JSON.parse(strContext) : {};
  context.rentalLocation = context.rentalLocation ? context.rentalLocation : 'Las Vegas';
  context.rentalFromDate = context.rentalFromDate ? moment(context.rentalFromDate) : moment();
  context.rentalUntilDate = context.rentalUntilDate ? moment(context.rentalUntilDate) : moment().add(2, 'day');
  return context;
}

const AppContext = React.createContext<AppContextType | null>(null);

const AppProvider: React.FC = ({ children }) => {
  const initialContext = getInitialContext();
  const [selectedCar, setSelectedCar] = useState<Car | null>(initialContext.selectedCar);
  const [rentalFromDate, setRentalFromDate] = useState<Moment | null>(initialContext.rentalFromDate);
  const [rentalUntilDate, setRentalUntilDate] = useState<Moment | null>(initialContext.rentalUntilDate);
  const [rentalLocation, setRentalLocation] = useState<string | null>(initialContext.rentalLocation);
  const [loginMethod, setLoginMethod] = useState<string | null>(initialContext.loginMethod);
  const [userEmail, setUserEmail] = useState<string | null>(initialContext.userEmail);

  useEffect(() => {
    localStorage.setItem(
      contextStorageKeyName,
      JSON.stringify({ selectedCar, rentalFromDate, rentalUntilDate, rentalLocation, loginMethod, userEmail }),
    );
  }, [selectedCar, rentalFromDate, rentalUntilDate, rentalLocation, loginMethod, userEmail]);

  const context: AppContextType = {
    selectedCar,
    setSelectedCar,
    rentalFromDate,
    setRentalFromDate,
    rentalUntilDate,
    setRentalUntilDate,
    rentalLocation,
    setRentalLocation,
    loginMethod,
    setLoginMethod,
    userEmail,
    setUserEmail,
  };
  return <AppContext.Provider value={context}>{children}</AppContext.Provider>;
};

export default AppContext;
export { AppProvider };
