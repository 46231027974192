import React, { useCallback, useEffect, useRef, useState } from 'react';
import { TextInputFormParams } from '../text-input/text-input.component';
import { FieldValidationFn, FormErrorMap } from './types';

interface FormProps<T> {
  children: React.ReactElement<TextInputFormParams<T>> | React.ReactElement<TextInputFormParams<T>>[];
  className?: string;
  value?: T;
  formValidators?: {
    [K in keyof T]: FieldValidationFn<T[K]>;
  };
  onSubmit?: (value: T) => void;
  onChange?: (value: T) => void;
  onError?: (errors: FormErrorMap<T>) => void;
  onFirstInteraction?: () => void;
}

/**
 * a form component that aims to take away the pain of keeping internal form state and error handling
 * @example
 * ```tsx
 * interface NameEmailFormFields {
 *   fullName?: string;
 *   emailAddress: string;
 * }
 * const NameEmailFormComponent = function () {
 *   const onSubmit = data => console.log(data);
 *   return (
 *     <Form
 *       onSubmit={onSubmit}
 *       formValidators={{
 *         emailAddress: validateEmail,
 *       }}
 *     >
 *       <TextInput<Pick<NameEmailFormFields, 'fullName'>> name="fullName" label="Full Name" placeholder="enter full name" className="full-name" type="text" />
 *
 *       <TextInput<Pick<NameEmailFormFields, 'emailAddress'>> name="emailAddress" label="Email Address" placeholder="enter email" className="email" type="email" />
 *
 *       <Button name="submit" className="submit-email" label="Submit" type="submit">
 *         Submit
 *       </Button>
 *     </Form>
 *   );
 * };
 * ```
 */
export const Form = function <T>(props: FormProps<T>): React.ReactElement {
  const { formValidators } = props;
  const [value, setValue] = useState<T>((props.value as T) || ({} as T));
  const [formErrors, setFormErrors] = useState<FormErrorMap<T>>();
  // we only show errors after submit button is clicked
  const [readyToShowError, setReadyToShowError] = useState<boolean>(false);
  // preventing form submit unless value has changed
  const [blockSubmit, setBlockSubmit] = useState<boolean>(false);

  useEffect(() => {
    props.value && setValue(props.value);
  }, [props.value]);

  const onCompleteClick = (event: React.FormEvent) => {
    event.preventDefault();
    if (blockSubmit) {
      return;
    }
    setBlockSubmit(true);
    const errors = formErrors && Object.values(formErrors).filter((x) => !!x);
    setReadyToShowError(true);
    if (errors && errors.length > 0) {
      // We break out here since at this point the validation error messages should be visible to the user
      return;
    }
    if (value && props.onSubmit) {
      props.onSubmit(value);
    }
  };

  const valueOnChange = (child: React.ReactElement<TextInputFormParams<T>>) => (val: T[keyof T]) => {
    setValue({
      ...value,
      [child.props.name]: val,
    });

    if (child.props.onChange) {
      child.props.onChange(val);
    }
  };

  useEffect(() => {
    setBlockSubmit(false);
    const keys = Object.keys(formValidators ?? {}) as Array<keyof T>;

    let newFormErrors = {};
    setFormErrors(newFormErrors as FormErrorMap<T>);

    keys.forEach((key: keyof T) => {
      const fn = formValidators?.[key];
      if (fn) {
        const error = fn(value[key]);
        const lastFormErrors = {
          [key]: error,
        };

        newFormErrors = {
          ...newFormErrors,
          ...lastFormErrors,
        };
      }
    });

    setFormErrors(newFormErrors as FormErrorMap<T>);
  }, [value]);

  useEffect(() => {
    if (props.onError && formErrors) {
      props.onError(formErrors);
    }
  }, [formErrors]);

  useEffect(() => {
    if (props.onChange) {
      props.onChange(value);
    }
  }, [value]);

  const formTouched = useRef(false);
  const onAnyFocus = useCallback(() => {
    if (!formTouched.current) props.onFirstInteraction?.();

    formTouched.current = true;
  }, []);

  const childrenWithProps = React.Children.map(
    props.children,
    (
      child: React.ReactElement<TextInputFormParams<T>, string | React.JSXElementConstructor<TextInputFormParams<T>>>,
    ) => {
      // Checking isValidElement is a safe way for tye checking
      if (React.isValidElement(child) && child.props && child.props.name) {
        const childProps = child.props;
        const name = childProps.name as keyof T;
        if (typeof name !== 'string') {
          console.warn('type is not string as expected!');
        }
        const childElm = React.cloneElement(child, {
          onChange: valueOnChange(child),
          value: value[name],
          error: (readyToShowError && formErrors?.[name]) || undefined,
          className: `ts-input ${child.props.className || ''}`,
          onFocus: (event) => {
            child.props.onFocus?.(event);
            onAnyFocus();
          },
        });

        return <div className={String(name)}>{childElm}</div>;
      }
      return <div>{child}</div>;
    },
  );

  return (
    <form aria-label="form" onSubmit={onCompleteClick} className={props.className} noValidate>
      {childrenWithProps}
    </form>
  );
};
