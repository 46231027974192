import { httpGet } from '../utils/http';

interface UserEmailRes {
  status: number;
  data: {
    result: any;
  };
}

export const checkUserEmail = async (email: string): Promise<UserEmailRes> => {
  return httpGet<UserEmailRes>(`/users/email/${email}`);
};
