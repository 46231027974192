import { isValidPhoneNumber } from 'libphonenumber-js';

export const emailRegex = new RegExp(
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
);

export const validateEmail = (email?: string | number) => {
  if (!emailRegex.test(String(email)) || !email) {
    return 'Invalid email address';
  }
  return null;
};

export const validatePhone = (phoneNumber: string | undefined) => {
  if (phoneNumber && !isValidPhoneNumber(phoneNumber)) {
    return 'Invalid phone number';
  }
  return null;
};
