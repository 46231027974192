import React, { MutableRefObject, useEffect, useRef } from 'react';
import styled from 'styled-components';
import Button from '../button/button.component';

interface MenuProps {
  className?: string;
  openButton: {
    label?: string;
    icon?: React.ReactNode;
  };
  items: {
    label: string;
    callback: () => void;
    icon?: React.ReactNode;
    className?: string;
  }[];
}

/**
 * Hook that alerts clicks outside of the passed ref
 */
function useOutsideAlerter(ref: MutableRefObject<HTMLElement | null>, handleClickOutside: () => void) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function isDidClickedOutside(event: MouseEvent) {
      if (ref?.current && !ref.current.contains(event.target as Node)) {
        handleClickOutside();
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', isDidClickedOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', isDidClickedOutside);
    };
  }, [ref]);
}

const MenuComponent: React.FC<MenuProps> = ({ className, openButton, items }) => {
  const wrapperRef = useRef<HTMLElement>(null);
  const summeryRef = useRef<HTMLElement>(null);

  const triggerCloseClick = () => {
    summeryRef.current?.click();
  };
  useOutsideAlerter(wrapperRef, () => {
    if (typeof (wrapperRef.current as any)?.open == 'boolean' && (wrapperRef.current as any)?.open) {
      triggerCloseClick();
    }
  });
  return (
    <div className={['menu-component', className].join(' ')}>
      <DetailsStyle ref={wrapperRef}>
        <MenuOpenerStyle ref={summeryRef}>{openButton?.icon && openButton.icon}</MenuOpenerStyle>
        <StyledList>
          {items.map((item, index) => (
            <StyledListItem key={index} title={item.label}>
              <StyledListButton
                className={['menu-button', item.className, className].join(' ')}
                onClick={() => {
                  item.callback();
                  triggerCloseClick();
                }}
              >
                {item.icon && <StyledIcon>{item.icon}</StyledIcon>}
                {item.label}
              </StyledListButton>
            </StyledListItem>
          ))}
        </StyledList>
      </DetailsStyle>
    </div>
  );
};

export default MenuComponent;

const DetailsStyle = styled.details`
  position: relative;
  z-index: 1;
  direction: rtl;
  ::-webkit-details-marker,
  ::marker {
    content: '';
    color: transparent;
  }
  &[open] {
    li {
      opacity: 1;
      height: 35px;
    }
  }
`;

const MenuOpenerStyle = styled.summary`
  border: none;
  outline: none;
  text-align: left;
  padding-top: 0px;
  padding-bottom: 0;
  display: inline-block;
  height: 36px;
  cursor: pointer;
  &::after {
    box-shadow: none;
    display: none;
  }
`;

const StyledIcon = styled.i`
  margin-right: 8px;
  line-height: 20px;
`;

const StyledListItem = styled.li`
  opacity: 0;
  height: 0;
  width: 320px;
  margin: 10px 0;
  direction: ltr;
  transition: all 0.3s ease-in-out;
`;

const StyledListButton = styled(Button)`
  width: 100%;
  text-align: left;
  font-family: var(--font-family-medium);
  font-size: 16px;
  line-height: 19px;
  border: none;
  box-shadow: none;

  &.ant-btn-default {
    color: black;
  }
  :hover:not(.disabled) {
    background-color: var(--color-primary);
  }

  &:hover:not(.disabled),
  &[active]:not(.disabled) {
    color: var(--button-color-primary);
  }

  i + span {
    vertical-align: middle;
  }
`;

const StyledList = styled.ul`
  position: absolute;
  background: white;
  margin-top: 15px;
  border-radius: 15px;
  padding: 15px;
  box-shadow: 0 10px 30px rgb(0 0 0 / 12%);
`;
