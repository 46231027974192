import React from 'react';
import { Spin } from 'antd';
import style from 'styled-components';

const SpinnerLoader: React.FC = function () {
  return <LoadingSpinner size="large" />;
};

const LoadingSpinner = style(Spin)`
  margin-top: 5px;
`;

export default SpinnerLoader;
