import { Input } from 'antd';
import React, { FocusEvent } from 'react';
import styled from 'styled-components';
export interface TextInputParams {
  value?: any;
  label?: string;
  placeholder?: string;
  className?: string;
  type?: 'email' | 'number' | 'text' | 'submit';
  disabled?: boolean;
  maxLength?: number;
  error?: string | null;
  defaultValue?: string;
  onChange?: (value: any) => void;
  ref?: (value: any) => void;
  style?: React.CSSProperties;
  children?: React.ReactNode | React.ReactNode[];
  onFocus?: (event: FocusEvent<HTMLElement>) => void;
  icon?: JSX.Element;
}

export interface TextInputFormParams<K> extends TextInputParams {
  name: keyof K;
  value?: K[keyof K] | string;
  onChange?: (value: K[keyof K]) => void;
}

interface FormInputParams<T> extends TextInputParams {
  value: T;
}

function TextInput<K = Record<string, never>>({ error, name, ...params }: TextInputFormParams<K>): JSX.Element;
function TextInput<T = string | number | boolean>({ error, ...params }: FormInputParams<T>): JSX.Element;
function TextInput({ error, label: labelStringsKey, children, icon, ...params }: TextInputParams): JSX.Element {
  return (
    <React.Fragment>
      <StyledInputComponent
        className={['input-component', params.className].join(' ')}
        style={{
          ...params.style,
        }}
      >
        <label
          style={{
            ...params.style,
            height: 20,
          }}
        >
          {labelStringsKey ?? ''}
        </label>
        <div
          style={{
            ...params.style,
            height: 55,
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Input
            name={params.type}
            type={params.type}
            className="ms-Dropdown-title"
            aria-label={'text-input'}
            placeholder={params.placeholder ?? ''}
            status={error ? 'error' : undefined}
            value={params.value ?? ''}
            onChange={(val: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
              if (params.onChange) {
                params.onChange(val.target.value);
              }
            }}
            onFocus={params?.onFocus}
            disabled={params.disabled}
            maxLength={params.maxLength}
          />
          {icon}
        </div>
        <label
          style={{
            ...params.style,
            height: 16,
            display: 'block',
            fontFamily: 'var(--font-family-light)',
            fontSize: 12,
            paddingBottom: 2,
          }}
          className="error-label"
        >
          {error ?? ''}
        </label>
      </StyledInputComponent>
    </React.Fragment>
  );
}

export default TextInput;

const StyledInputComponent = styled.div`
  display: block;
  input:not([type='submit']) {
    height: 54px;
  }

  input {
    border-radius: 4px;
  }

  .error {
    color: red;
  }
  label {
    font-size: 14px;
  }
`;
