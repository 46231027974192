import { Button as AntButton, ButtonProps } from 'antd';
import React from 'react';
import styled, { css } from 'styled-components';

const Button: React.FC<ButtonProps> = function ({ children, className, ...props }) {
  const button = (
    <StyledAntButton className={['button-component', className].join(' ')} {...props}>
      {children}
    </StyledAntButton>
  );
  return button;
};

export default Button;

const ButtonCss = css`
  &.ant-btn-primary,
  &.ant-btn-default,
  &.ant-btn-link {
    font-family: var(--font-family-regular);
  }
  &.ant-btn-primary {
    background-color: var(--button-color-primary);
    border-color: var(--button-color-primary);
    width: 181px;
  }
  &.ant-btn-default {
    border-color: var(--button-color-primary);
    color: var(--button-color-primary);
  }
  &.ant-btn-link {
    color: var(--button-color-primary);
  }
  svg + span {
    margin-left: 8px;
  }
  &.link {
    --antd-wave-shadow-color: transparent;
    background-color: transparent;
    border: none;

    &::after {
      box-shadow: none;
      color: transparent;
    }
    &.ant-btn:hover,
    &.ant-btn:focus,
    &.ant-btn:active {
      background-color: transparent;
      border-color: transparent;
    }
  }
  &.xxl {
    width: 400px;
    height: 54px;
    .icon {
      padding-right: 10px;
    }
  }
  &.disabled {
    cursor: default;
    &:hover {
      background-color: transparent;
      border-color: transparent;
      &.ant-btn-primary {
        background-color: var(--button-color-primary);
        border-color: var(--button-color-primary);
        width: 181px;
      }
    }
  }
`;

const StyledAntButton = styled(AntButton)`
  border-radius: 4px;
  height: 34px;
  ${ButtonCss}
`;
