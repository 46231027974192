import { Button, Image, Space } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';
import AppContext, { AppContextType } from '../../contexts/app.context';
import AuthenticationDialogComponent from '../../pages/authentication-flow/sign-in-flow.dialog';
import { routes } from '../../routes/main.routes';
import { clearUserCookie, clearUserTokenCookie } from '../../store/user-management';
import { userInfoState } from '../../store/user-model';
import MenuComponent from '../menu/menu.component';
import { mobileDetection } from '../../utils/is-mobile';

const HeaderFunctions: React.FC = () => {
  const [isMobile] = useState(mobileDetection());
  const nameMaxLength = 25;
  const navigate = useNavigate();
  const info = useRecoilValue(userInfoState);
  const { setUserEmail } = useContext<AppContextType | null>(AppContext) as AppContextType;

  const ButtonType = isMobile ? 'link' : 'primary';

  useEffect(() => {
    const carReservation = localStorage.getItem('car_reservation');
    if (info?.isAuthenticated) {
      setUserEmail(info.email);
    }
    if (info?.isAuthenticated && carReservation) {
      navigate(routes['complete-reservation'].path);
    }
  }, [info?.isAuthenticated]);
  return (
    <>
      {/* to debug user info unquote this  */}
      {/* <span>{JSON.stringify(info || {})}</span> */}
      {info?.isAuthenticated && (
        <>
          <MenuComponent
            className={'menu'}
            openButton={{
              icon: <Image src="/user-circle-icon.svg" preview={false} />,
            }}
            items={[
              {
                label: info.email
                  ? info.email.length > nameMaxLength
                    ? info.email.slice(0, nameMaxLength) + '...'
                    : info.email
                  : '',
                callback: () => {
                  //
                },
                icon: <Image src="/user-icon.svg" preview={false} />,
                className: 'disabled',
              },
              {
                label: 'My Account',
                callback: () => {
                  navigate(routes['user-details'].path);
                },
              },
              {
                label: 'My Reservations',
                callback: () => {},
              },
              {
                label: 'Sign out',
                callback: () => {
                  clearUserTokenCookie();
                  clearUserCookie();
                  window.location.href = '/';
                },
              },
            ]}
          />
        </>
      )}
      <div hidden={info?.isAuthenticated} className={'menu'}>
        <LearnMoreButton type="text" className="tip-learn-more">
          Learn more
        </LearnMoreButton>
        <Space />
        <AuthenticationDialogComponent
          initialDialogState={'sign-up'}
          buttonText={'Reserve a car'}
          buttonType={ButtonType}
        />
      </div>
    </>
  );
};

const LearnMoreButton = styled(Button)`
  @media only screen and (max-width: 576px) {
    display: none;
  }
`;

export default HeaderFunctions;
