import { Image } from 'antd';
import React, { Suspense } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { routes } from '../../routes/main.routes';
import Button from '../button/button.component';
import SpinnerLoader from '../loading-block/loading-block.component';
import HeaderFunctions from './header-functions.component';

interface PageHeaderProps {
  className?: string;
}

const PageHeader: React.FC<PageHeaderProps> = function ({ className }) {
  const navigate = useNavigate();
  return (
    <StyledPageHeader className={[className].join(' ')}>
      <StyledButton
        onClick={() => {
          navigate(routes['/'].path);
        }}
        className={'logo'}
      >
        <Image preview={false} src={'/fastrentlogo.svg'} alt="logo" />
      </StyledButton>
      <Suspense fallback={<SpinnerLoader />}>
        <HeaderFunctions />
      </Suspense>
    </StyledPageHeader>
  );
};

export default PageHeader;

const StyledPageHeader = styled.header`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #f0f0f0;
  padding: 11px;
  background-color: transparent;
  height: 60px;
  position: relative;
  z-index: 2;
`;
const StyledButton = styled(Button)`
  --antd-wave-shadow-color: transparent;
  border: none;
  &:focus {
    box-shadow: none;
    color: white;
    border-color: white;
    background: #fff;
  }
  &::after {
    box-shadow: none;
    color: transparent;
  }
`;
