import { httpPut } from './http';

interface UpdateUserRes {
  data: {
    auth_session_id: string;
    device_binding_token: string;
  };
  bindId_api_url: string;
}

export class UserIdApi {
  static async updateUserRegisteredAtBindId(userId: string) {
    return httpPut<UpdateUserRes>(`/updateUser/${userId}`, {
      custom_app_data: { registeredAtBindId: true },
    });
  }
}
