import { Button } from 'antd';
import React from 'react';
import styled from 'styled-components';

interface ButtonProps {
  onClick?: () => void;
  name?: string;
  className?: string;
  label?: string;
  type?: 'submit';
  icon?: React.ReactNode;
  disabled?: boolean;
}

const SubmitButtonComponent: React.FC<ButtonProps> = function ({ onClick, label, className, icon, disabled }) {
  const submit = (
    <SubmitWrapper className="submit-wrapper">
      <Button
        className={['button-component', 'submit-button', className].join(' ')}
        htmlType="submit"
        onClick={onClick}
        size="large"
        icon={icon && <IcosWrapper>{icon}</IcosWrapper>}
        block
        disabled={disabled}
      >
        {label}
      </Button>
    </SubmitWrapper>
  );
  return submit;
};

export default SubmitButtonComponent;

const SubmitWrapper = styled.div`
  position: relative;
  .submit-button {
    cursor: pointer;
    background-color: var(--button-color-primary);
    border-color: var(--button-color-primary);
    color: white;
  }
  .submit-button-icon {
    position: absolute;
    line-height: 54px;
    top: 0px;
    left: 35%;
    color: white;
  }
`;

const IcosWrapper = styled.span`
  margin: 0 8px;
`;
