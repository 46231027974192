import { Button, Image, Spin } from 'antd';
import React, { Suspense, useContext, useState } from 'react';
import styled from 'styled-components';
import AppContext, { AppContextType } from '../../contexts/app.context';
import ThreatDetectedDialog from '../../pages/authentication-flow/threat-detected-dialog.component';
import { validateEmail } from '../../utils/validations';
import SocialLoginOptions from '../authentication-method/social-login-options';
import SubmitButtonComponent from '../button/submit-button.component';
import { Form } from '../form/form';
import TextInput from '../text-input/text-input.component';

interface CollectEmailFormComponentProps {
  onSubmit: (value: string) => void;
  isLoading: boolean;
}
interface NameEmailFormFields {
  emailAddress: string;
}

const CollectEmailFormComponent: React.FC<CollectEmailFormComponentProps> = function ({ onSubmit, isLoading = false }) {
  const { userEmail, setUserEmail, loginMethod, setLoginMethod } = useContext<AppContextType | null>(
    AppContext,
  ) as AppContextType;
  const [dialogStep, setDialogStep] = useState<'select-method' | 'threat-detected'>('select-method');
  if (dialogStep === 'threat-detected') return <ThreatDetectedDialog />;
  const clearAuthMethod = () => {
    setUserEmail(null);
    setLoginMethod(null);
  };

  const isKnownDevice = () => {
    return userEmail && loginMethod;
  };
  return (
    <StyledEmailForm>
      <Form<NameEmailFormFields>
        className="block"
        onSubmit={(value) => {
          onSubmit(value.emailAddress);
        }}
        formValidators={{
          emailAddress: (email) => {
            return validateEmail(email);
          },
        }}
      >
        <h2 className={'title'}>Sign in</h2>
        <StyledSubTitle>to continue with your reservation</StyledSubTitle>
        {isKnownDevice() ? (
          <div>
            <StyledSubTitle>
              <strong>{userEmail}</strong>
            </StyledSubTitle>
          </div>
        ) : (
          <></>
        )}
        {!isKnownDevice() ? (
          <TextInput<Pick<NameEmailFormFields, 'emailAddress'>>
            style={{}}
            name="emailAddress"
            placeholder="Enter your email"
            className="email"
            type="email"
          />
        ) : (
          <></>
        )}
        {!isKnownDevice() ? (
          <Spin size="large" spinning={isLoading}>
            <SubmitButtonComponent
              name="submit"
              className="submit-email"
              label="Continue"
              type="submit"
              icon={<Image src="/fingerprint.svg" preview={false} />}
            />
          </Spin>
        ) : (
          <></>
        )}
      </Form>

      <Suspense fallback={'Loading...'}>
        {!isKnownDevice() && (
          <StyledDivider>
            <hr />
            <span>or</span>
          </StyledDivider>
        )}
        <SocialLoginOptions
          onSubmit={onSubmit}
          isLoading={isLoading}
          threatDetected={() => setDialogStep('threat-detected')}
        />
      </Suspense>
      {isKnownDevice() && (
        <SwitchAccountDiv>
          Not you?
          <StyledSwitchAccountButton onClick={clearAuthMethod} type="text" id="tip-switch-account">
            Switch account
          </StyledSwitchAccountButton>
        </SwitchAccountDiv>
      )}
    </StyledEmailForm>
  );
};

export default CollectEmailFormComponent;

const StyledSwitchAccountButton = styled(Button)`
  padding-left: 5px;
  color: var(--button-color-primary);
`;

const SwitchAccountDiv = styled.div`
  font-family: var(--font-family-regular);
  position: absolute;
  bottom: 20px;
`;
const StyledEmailForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  form {
    width: 100%;
  }

  input {
    height: 54px;
    width: 100%;
  }
  .emailAddress {
    margin-top: 30px;
  }
`;

const StyledDivider = styled.div.attrs({ className: 'style-divider' })`
  position: relative;
  margin-top: 26px;
  margin-bottom: 26px;
  overflow: hidden;
  width: 100%;
  span {
    position: absolute;
    top: -5px;
    left: 45%;
    width: 10%;
    background-color: white;
    text-align: center;
  }
  hr {
    width: 600px;
  }
`;

const StyledSubTitle = styled.h4`
  margin-bottom: 35px;
`;
