import { Image } from 'antd';
import React from 'react';
import styled from 'styled-components';

const ThreatDetectedDialog: React.FC = () => {
  return (
    <StyledDiv>
      <StyledH2>Threat Detected</StyledH2>
      <StyledImage src="/threat_detected_icon.svg" preview={false} />
      <div>
        <StyledLink href={'/'} type={'link'} target={'_self'}>
          Learn more
        </StyledLink>
        <StyledWarningSection>
          <StyledIcon src="/warning_icon.svg" preview={false} />
          <StyledText>
            This message is shown for demo purposes, in production environments make sure you do not disclose any
            security related insights
          </StyledText>
        </StyledWarningSection>
      </div>
    </StyledDiv>
  );
};

export default ThreatDetectedDialog;

const StyledDiv = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const StyledH2 = styled.h2`
  margin-top: 0;
  margin-top: 0;
  margin-bottom: 0;
`;
const StyledWarningSection = styled.section`
  background: #ffb8b8;
  border-radius: 16px;
  padding: 16px 12px;
  text-align: left;
  margin-bottom: 15px;
`;
const StyledImage = styled(Image)`
  margin: 0;
  width: 175px;
`;
const StyledIcon = styled(Image)`
  margin-bottom: 3px;
  width: 20px;
`;
const StyledText = styled.p`
  font-family: var(--font-family-medium);
  font-size: 12px;
  line-height: 15px;
  margin: 0 !important;
  text-align: left !important;
`;
const StyledLink = styled.a`
  text-decoration: underline;
  margin-bottom: 10px;
`;
