import { decodeToken } from 'react-jwt';
import { httpPost } from './http';
import { UserIdApi } from './userid-api';

interface GetBindIdConfigResponse {
  bindId_api_url: string;
  client_id: string;
}

interface StartBindIdSessionResponse {
  data: {
    auth_session_id: string;
    device_binding_token: string;
  };
}

interface CompleteBindIdSessionResponse {
  data: {
    id_token: string;
    access_token: string;
    refresh_token: string;
    is_user_create: boolean;
  };
}

export class BindIdApiClient {
  static async useBindIdLoginUserFlow(userName: string) {
    // TODO: separate BindId flow and UserId flow
    const sdk = window.WebAuthnSdk;

    const config = await BindIdApiClient.bindIdConfiguration();
    const bindIdApiUrl = config.bindId_api_url;
    const clientId = config.client_id;

    await sdk.init(clientId, { serverPath: bindIdApiUrl });
    await sdk.prepareWebauthnAuthentication(userName);
    const authCode = await sdk.executeWebauthnAuthentication();
    const completeRes = await BindIdApiClient.completeBindIdAuthSession(authCode);

    const decodedIdToken: any = decodeToken(completeRes.data.access_token);

    return {
      userId: decodedIdToken?.sub,
      accessToken: completeRes.data.access_token,
    };
  }

  static async useBindIDRegisterUserFlow(userName: string): Promise<{ userId: string; accessToken: string }> {
    // TODO: separate BindId flow and UserId flow
    const sdk = window.WebAuthnSdk;

    const config = await BindIdApiClient.bindIdConfiguration();
    const bindIdApiUrl = config.bindId_api_url;
    const clientId = config.client_id;

    const sessionRes = await BindIdApiClient.startBindIdAuthSession(userName);
    const authSessionId = sessionRes.data.auth_session_id;

    await sdk.init(clientId, { serverPath: bindIdApiUrl });
    await sdk.prepareWebauthnRegistration(userName, userName, authSessionId);
    const authCode = await sdk.executeWebauthnRegistration();
    const completeRes = await BindIdApiClient.completeBindIdAuthSession(authCode);
    const decodedToken: any = decodeToken(completeRes.data.access_token);
    await UserIdApi.updateUserRegisteredAtBindId(decodedToken.sub);
    const knownUsersStr = localStorage.getItem('knownUsers');
    const knownUsers: string[] = knownUsersStr ? JSON.parse(knownUsersStr) : [];
    if (!knownUsers.includes(userName)) knownUsers.push(userName);
    localStorage.setItem('knownUsers', JSON.stringify(knownUsers));
    return {
      userId: decodedToken.sub,
      accessToken: completeRes.data.access_token,
    };
  }

  static async bindIdConfiguration() {
    return httpPost<GetBindIdConfigResponse>('/bindIdConfiguration', {});
  }

  static async startBindIdAuthSession(userName: string) {
    return httpPost<StartBindIdSessionResponse>('/startBindIdAuthSession', { userName });
  }

  static async completeBindIdAuthSession(authCode: string) {
    return httpPost<CompleteBindIdSessionResponse>('/completeBindIdAuthSession', { authCode });
  }
}
