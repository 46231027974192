import React, { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { asyncUserIdState, userInfoState } from './user-model';
import Cookies from 'universal-cookie';

const cookies = new Cookies();
const UserManagement: React.FC = () => {
  const [userInfo] = useRecoilState(userInfoState);
  const [, setUserId] = useRecoilState(asyncUserIdState);

  useEffect(() => {
    if (cookies.get('userId') === 'undefined') {
      cookies.remove('userId');
    }
    if (!userInfo?.isAuthenticated) {
      const userId = cookies.get('userId');
      setUserId(userId);
      window['myRiskID']?.setUser(userId);
    }
  }, [userInfo]);

  return <></>;
};

export function setUserCookie(userId: string) {
  cookies.set('userId', userId, {
    expires: new Date(new Date(new Date().getTime() + 10 * 60 * 1000)),
  });
  window['myRiskID']?.setUser(userId);
}

export function getUserTokenCookie() {
  return cookies.get('userToken');
}

export function setUserTokenCookie(token: string) {
  cookies.set('userToken', token, {
    expires: new Date(new Date(new Date().getTime() + 10 * 60 * 1000)),
  });
}

export function clearUserCookie() {
  cookies.remove('userId');
}

export function clearUserTokenCookie() {
  cookies.remove('userToken');
}

export default UserManagement;
