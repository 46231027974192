import { useBoolean } from '@fluentui/react-hooks';
import { FC, useMemo, useState } from 'react';
import { useRecoilState } from 'recoil';

import Button from '../../components/button/button.component';
import CollectEmailFormComponent from '../../components/sign-in-component/sign-in.component';
import DialogOpenComponent from '../../components/dialog/dialog.component';
import { userState } from '../../store/user-model';
import ThreatDetectedDialog from './threat-detected-dialog.component';
import styled from 'styled-components';
import { useUserSignUpFlow } from '../../hooks/useUserSignUpFlow';

interface DialogComponentProps {
  className?: string;
  initialDialogState: 'sign-up';
  buttonText: string;
  buttonType: 'primary' | 'link';
  style?: React.CSSProperties;
  result?: (res: any) => void;
  showDialog?: () => void;
}
const AuthenticationDialogComponent: FC<DialogComponentProps> = ({
  initialDialogState,
  result,
  showDialog = () => null,
  buttonText,
  buttonType,
}) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [dialogState] = useState<'sign-up'>(initialDialogState);
  const [userModel] = useRecoilState(userState);
  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
  const [dialogStep, setDialogStep] = useState<'collect-email' | 'threat-detected' | 'risk-challenge'>('collect-email');
  const userSignUpFlow = useUserSignUpFlow();

  const submitEmailAddress = async (emailAddress: string) => {
    setIsProcessing(true);

    const { status } = await userSignUpFlow(emailAddress);

    if (status === 'threat-detected') {
      setDialogStep('threat-detected');
    }

    if (status === 'error' || status === 'success') {
      toggleHideDialog();
    }

    setIsProcessing(false);

    if (result) {
      result(userModel);
    }
  };

  const collectEmailDialog = useMemo(() => {
    return <CollectEmailFormComponent onSubmit={(value) => submitEmailAddress(value)} isLoading={isProcessing} />;
  }, [dialogState, isProcessing]);

  return (
    <>
      <CallToActionButton
        type={buttonType}
        onClick={() => {
          toggleHideDialog();
          showDialog();
        }}
      >
        {buttonText}
      </CallToActionButton>
      <DialogOpenComponent
        isVisible={!hideDialog}
        onDismiss={() => {
          toggleHideDialog();
          setDialogStep('collect-email');
        }}
      >
        {dialogStep === 'collect-email' && collectEmailDialog}
        {dialogStep === 'threat-detected' && <ThreatDetectedDialog />}
      </DialogOpenComponent>
    </>
  );
};

const CallToActionButton = styled(Button)`
  margin-top: 6px;
  font-weight: 600;
`;

export default AuthenticationDialogComponent;
