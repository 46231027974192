import { Image, Spin } from 'antd';
import React, { useContext } from 'react';
import { useRecoilValue } from 'recoil';
import { loginOptionsState } from '../../store/config-model';
import Button from '../button/button.component';
import AppContext, { AppContextType } from '../../contexts/app.context';
import AuthenticateWithBindId from './authenticate-with-bindId';
import styled from 'styled-components';

interface SocialLoginOptionsProps {
  onSubmit: (email: string) => void;
  threatDetected: () => void;
  isLoading: boolean;
}

const SocialLoginOptions: React.FC<SocialLoginOptionsProps> = ({ onSubmit, threatDetected, isLoading }) => {
  const socialLogins = useRecoilValue(loginOptionsState);
  const { userEmail, loginMethod, setLoginMethod } = useContext<AppContextType | null>(AppContext) as AppContextType;

  const isKnownDevice = () => {
    return userEmail && loginMethod;
  };

  const socialLoginRedirect = async (redirectUrl: string) => {
    const recommendation = (await window.myRiskID?.triggerActionEvent('login'))?.recommendation;
    console.log('login recommendation', recommendation);
    if (recommendation?.type === 'DENY') {
      threatDetected();
      return;
    }

    window.location.replace(redirectUrl);
  };

  const getLoginButtonProvider = (provider: string): { svg: string; name: string; click: any } => {
    if (provider === 'google') {
      return {
        svg: '/google.svg',
        name: 'Google',
        click: () => socialLoginRedirect(socialLogins.loginOptions?.google),
      };
    }
    if (provider === 'facebook') {
      return {
        svg: '/facebook.svg',
        name: 'Facebook',
        click: () => socialLoginRedirect(socialLogins.loginOptions?.facebook),
      };
    }
    if (provider === 'apple') {
      return { svg: '/apple.svg', name: 'Apple', click: () => socialLoginRedirect(socialLogins.loginOptions?.apple) };
    }

    return { svg: '', name: '', click: undefined };
  };

  if (isKnownDevice()) {
    const provider = getLoginButtonProvider(loginMethod ?? '');
    return loginMethod === 'bindId' ? (
      <Spin size="large" spinning={isLoading}>
        <AuthenticateWithBindId callback={() => onSubmit(userEmail ?? '')} />
      </Spin>
    ) : (
      <Button
        style={{ color: 'black', borderColor: 'black', width: '90%', height: 54 }}
        onClick={() => {
          provider.click();
        }}
        id={'tip-auth-' + provider.name}
      >
        <Image src={provider.svg} /> <span>&nbsp;Continue with {provider.name}</span>
      </Button>
    );
  }

  return (
    <SocialLoginButtons>
      <SocialLoginOptionWrapper
        onClick={() => {
          setLoginMethod('google');
          socialLoginRedirect(socialLogins.loginOptions?.google);
        }}
        id="tip-auth-google"
      >
        <Image src={'/google.svg'} preview={false} />
        <span className="hide-below-sm">Google</span>
      </SocialLoginOptionWrapper>
      <SocialLoginOptionWrapper
        onClick={() => {
          setLoginMethod('facebook');
          socialLoginRedirect(socialLogins.loginOptions?.facebook);
        }}
        id="tip-auth-facebook"
      >
        <Image src={'/facebook.svg'} preview={false} />
        <span className="hide-below-sm">Facebook</span>
      </SocialLoginOptionWrapper>
      <SocialLoginOptionWrapper
        onClick={() => {
          setLoginMethod('apple');
          socialLoginRedirect(socialLogins.loginOptions?.apple);
        }}
        id="tip-auth-apple"
      >
        <Image src={'/apple.svg'} preview={false} />
        <span className="hide-below-sm">Apple</span>
      </SocialLoginOptionWrapper>
    </SocialLoginButtons>
  );
};

export default SocialLoginOptions;

const SocialLoginButtons = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin: 0 auto;

  button:first {
    margin-left: 0;
  }
  button {
    margin-left: 4px;
  }
`;

const SocialLoginOptionWrapper = styled(Button)`
  && {
    width: 130px;
    border: 1px solid #c4c4c4;
    border-radius: 4px;
    height: 43px;
    color: black;
  }

  > span {
    margin-left: 10px;
  }

  .ant-image-img {
    height: 18px;
    width: 18px;
    vertical-align: text-bottom;
  }
`;
