import { Typography } from 'antd';
import React from 'react';

const NotFoundPage: React.FC = function () {
  return (
    <div>
      <Typography.Title>404 - Page Not Found</Typography.Title>
    </div>
  );
};

export default NotFoundPage;
