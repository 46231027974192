import React, { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import MainLayout from './components/main-layout/main-layout.component';
import RootRoutes from './routes/main.routes';
import { RecoilRoot } from 'recoil';
import './app.scss';
import { AppProvider } from './contexts/app.context';
import UserManagement from './store/user-management';

const App: React.FC = function () {
  return (
    <BrowserRouter>
      <RecoilRoot>
        <AppProvider>
          <MainLayout>
            <Suspense fallback={'loading...'}>
              <UserManagement />
            </Suspense>
            <RootRoutes />
          </MainLayout>
        </AppProvider>
      </RecoilRoot>
    </BrowserRouter>
  );
};

export default App;
