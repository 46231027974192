import UAParser from 'ua-parser-js';
import { useState, useEffect } from 'react';

export function mobileDetection() {
  const [width, setWidth] = useState<number>(window.innerWidth);
  const [isMobileAgent, setIsMobileAgent] = useState<boolean>(false);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    try {
      const deviceType = UAParser().device?.type;
      const mobileAgent = deviceType === 'mobile';
      setIsMobileAgent(mobileAgent);

      window.addEventListener('resize', handleWindowSizeChange);
    } catch (e) {
      console.log('Error:', e);
    }

    // BUG: the cleanup doesn't remove the event and keep capture new data
    return function cleanup() {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return width <= 768 || isMobileAgent;
}
