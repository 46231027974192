import { urlFragmentFormat } from './url-format/url-format';

type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE';

const REQUEST_HEADERS = { 'Content-Type': 'application/json' };

type RequestBody = any;

export type HttpResponse<T> = Response & T;
export type ResponseError = { message: string }; // TODO: replace with proper error

export function init(method: HttpMethod, body?: RequestBody, headers?: HeadersInit): RequestInit {
  return {
    method,
    headers: {
      ...REQUEST_HEADERS,
      ...(headers || {}),
    },
    body: (body && JSON.stringify(body || {})) ?? undefined,
  };
}

function httpFetchBuilder(
  path: string,
  type: 'POST' | 'PUT',
  body: RequestBody,
  params: URLSearchParams | undefined,
  headers?: HeadersInit,
): Promise<Response>;
function httpFetchBuilder(
  path: string,
  type: 'GET' | 'DELETE',
  body?: RequestBody,
  params?: URLSearchParams,
  headers?: HeadersInit,
): Promise<Response>;
// implementation of HTTP fetch API. supports POST/GET and returns a promise of a result/failure.
// full structure for response/failure TBD.
function httpFetchBuilder(
  path: string,
  httpMethod: HttpMethod,
  body?: RequestBody,
  params?: URLSearchParams,
  headers?: HeadersInit,
): Promise<Response> {
  const reqUrl = urlFragmentFormat(path, params);
  const requestInit = init(httpMethod, body, headers);
  // when debugging, use the following line to see the request details
  // console.log('requestInit: ', requestInit);

  return fetch(reqUrl, requestInit);
}

async function fetchRequest(
  path: string,
  httpMethod: HttpMethod,
  body?: RequestBody,
  params?: URLSearchParams,
  headers?: HeadersInit,
): Promise<Response> {
  let response;
  if (httpMethod === 'GET' || httpMethod === 'DELETE') {
    response = await httpFetchBuilder(path, httpMethod, body, params, headers);
  } else {
    response = await httpFetchBuilder(path, httpMethod, body, params, headers);
  }
  // when debugging, check the response status code and response body
  // console.log('response: ', response);

  if (!response.ok) {
    throw new Error('Request failed.');
  }
  return response;
}

/**
 * constructs a `GET` request
 * @param path API path
 * @param params request parameters
 * @returns a promise of the response body if successful and throw an error if failed
 */
export async function httpGet<T>(
  path: string,
  params?: URLSearchParams,
  headers?: HeadersInit,
): Promise<HttpResponse<T>> {
  const httpRequest = await fetchRequest(path, 'GET', undefined, params, headers);
  return httpRequest.json();
}
/**
 * constructs a `POST` request
 * @param path API path
 * @param data content of the request
 * @param params request parameters
 * @returns a promise of the response body if successful and throw an error if failed
 */
export async function httpPost<T>(
  path: string,
  data: RequestBody,
  params?: URLSearchParams,
  headers?: HeadersInit,
): Promise<T> {
  const httpRequest = await fetchRequest(path, 'POST', data, params, headers);
  return httpRequest.json(); // parses JSON response into native JavaScript objects
}
/**
 * constructs a `PUT` request
 * @param path API path
 * @param data content of the request
 * @param params request parameters
 * @returns a promise of the response body if successful and throw an error if failed
 */
export async function httpPut<T>(
  path: string,
  data: RequestBody,
  params?: URLSearchParams,
  headers?: HeadersInit,
): Promise<HttpResponse<T>> {
  const httpRequest = await fetchRequest(path, 'PUT', data, params, headers);
  return httpRequest.json();
}
/**
 * constructs a `DELETE` request
 * @param path API path
 * @param body content of the request
 * @param params request parameters
 * @returns a promise of the response body if successful and throw an error if failed
 */
export async function httpDelete<T>(path: string, headers?: HeadersInit): Promise<HttpResponse<T>> {
  const httpRequest = await fetchRequest(path, 'DELETE', undefined, undefined, headers);
  return httpRequest.json();
}
