import { atom, selector } from 'recoil';
import { httpGet } from '../utils/http';

type SocialLoginOptions = 'google' | 'facebook' | 'apple';

interface ConfigProps {
  loginOptions?: {
    [key in SocialLoginOptions]: string;
  };
}
export const CONFIG_STORE_NAME = 'configState';
export const CONFIG_SOCIALS_STORE_NAME = 'configSocialsState';

export const configModelState = atom<ConfigProps>({
  key: CONFIG_STORE_NAME,
  default: {},
});

export const loginOptionsState = selector({
  key: CONFIG_SOCIALS_STORE_NAME,
  get: async () => {
    const res = await httpGet<{ socialLoginUrls: { [key in SocialLoginOptions]: string } }>('/userIdConfiguration');
    return {
      loginOptions: res.socialLoginUrls,
    };
  },
});
