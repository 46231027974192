import { Layout } from 'antd';
import React from 'react';
import styled from 'styled-components';
import PageHeader from '../page-header/page-header.component';
import { useLocation } from 'react-router-dom';

const hideHeader: Array<string> = ['/return-to-desktop-page'];

const MainLayout: React.FC = function ({ children }) {
  const location = useLocation();
  const showHeader = !hideHeader.includes(location.pathname);

  return (
    <MainLayoutStyle>
      {showHeader && <StyledPageHeader className={'layout-header'} />}
      <StyledLayoutContent className={'layout-content'}>{children}</StyledLayoutContent>
    </MainLayoutStyle>
  );
};

export default MainLayout;

const StyledPageHeader = styled(PageHeader)`
  display: flex;
`;

const StyledLayoutContent = styled(Layout)`
  background-color: transparent;
  margin: auto;
  max-width: 1200px;
`;

const MainLayoutStyle = styled.div`
  position: relative;
  background-color: #fff;
`;
