import { BindIdApiClient } from '../../utils/bindid-api';

export const bindIdSignIn = async (username: string) => {
  try {
    return await BindIdApiClient.useBindIdLoginUserFlow(username);
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const bindIdSignUp = async (emailAddress: string) => {
  try {
    return await BindIdApiClient.useBindIDRegisterUserFlow(emailAddress);
  } catch (e) {
    console.error(e);
    return null;
  }
};
