import { decodeToken } from 'react-jwt';
import { atom, selector } from 'recoil';
import { httpGet } from '../utils/http';
import { setUserCookie, setUserTokenCookie } from './user-management';

export type UserInfo = {
  isAuthenticated: boolean;
  email?: string;
  birthday?: string;
  phoneNumber?: string;
  fullName?: string;
  country?: string;
  city?: string;
  street?: string;
  gender?: 'Male' | 'Female' | 'Other';
  userId?: string;
  customAppData?: any;
  isEmailVerified?: boolean;
};

export interface UserProps {
  bindId_userId?: string;
  isBindIdUser?: boolean;
  isTransmitUser?: boolean;
  userInfo?: UserInfo;
  userId?: string;
}
export const DEFAULT_USER: UserProps = {
  isBindIdUser: false,
  isTransmitUser: false,
};
export const USER_STORE_NAME = 'userState';
export const userState = atom<UserProps>({
  key: USER_STORE_NAME,
  default: DEFAULT_USER,
});

const userIdAtom = atom({
  key: 'userId',
  default: undefined,
});

export const asyncUserIdState = selector({
  key: 'async-user-id',
  async get({ get }) {
    const userId = get(userIdAtom);
    if (userId) {
      return userId;
    }
    const asyncToken = await get(asyncUserIdTokenStateSelector);
    if (!asyncToken || !asyncToken.token) {
      return undefined;
    }
    const decodedIdToken: any = decodeToken(asyncToken.token.id_token);
    return decodedIdToken?.sub;
  },
  set({ set }, newValue) {
    set(userIdAtom, newValue);
  },
});

const userIdTokenState = atom<{ token: any } | null>({
  key: 'token',
  default: {
    token: null,
  },
});

export const asyncUserIdTokenStateSelector = selector({
  key: 'async-token',
  async get({ get }) {
    const tokenState = get(userIdTokenState);
    if (tokenState?.token !== null) {
      return tokenState;
    }
    const params = new URLSearchParams(window.location.search);
    const code = params.get('code');
    if (!code) {
      return {
        token: null,
      };
    }
    let getTokenRes;
    try {
      getTokenRes = await httpGet<{ token: any }>('/userToken', new URLSearchParams({ code }));
      setUserTokenCookie(getTokenRes.token.access_token);
    } catch (e) {
      console.error(e);
      console.error('invalid code', code);
    }
    return getTokenRes;
  },
  set({ set }, newValue) {
    set(userIdTokenState, newValue ?? { token: null });
  },
});

export const userModelDataState = selector({
  key: 'userModel',
  async get({ get }) {
    const userData = get(userState);
    const userId = await get(asyncUserIdState);
    if (!userId || userData.userId) {
      return {
        ...userData,
      } as UserProps;
    }
    setUserCookie(userId);
    const userIdKnownUser = await httpGet<{ data: any }>(`/userDetails/${userId}`);
    // console.log('*** userModelDataState/userIdKnownUser **');
    // console.log(userIdKnownUser);
    // console.log('*** userModelDataState/userIdKnownUser **');
    const res = {
      ...userData,
      userId,
      userInfo: {
        customAppData: userIdKnownUser.data?.custom_app_data,
        email: userIdKnownUser.data?.email?.value ?? userIdKnownUser.data?.identity_providers[0]?.identifier,
        fullName: `${userIdKnownUser.data?.name?.first_name || ''} ${userIdKnownUser.data?.name?.last_name || ''}`,
        birthday: userIdKnownUser.data?.birthday,
        phoneNumber: userIdKnownUser.data?.custom_app_data?.phoneNumber,
        country: userIdKnownUser.data?.custom_app_data?.country,
        city: userIdKnownUser.data?.custom_app_data?.city,
        isAuthenticated: true,
        isEmailVerified: userIdKnownUser.data?.email?.email_verified,
      },
      bindId_userId: userIdKnownUser.data?.custom_app_data?.bindId_userId,
    };
    // console.log('*** userModelDataState **');
    // console.log(res);
    // console.log('*** userModelDataState **');
    return res;
  },
  set({ set }, newValue) {
    set(userState, newValue);
  },
});
export const USER_INFO_NAME = 'userInfo';
export const userInfoState = selector<UserInfo>({
  key: USER_INFO_NAME,
  get({ get }) {
    const user = get(userModelDataState);
    // console.log('*** userInfoState **');
    // console.log(user);
    // console.log('*** userInfoState **');
    return user.userInfo;
  },
  set({ set, get }, newValue) {
    const user = get(userModelDataState);
    set(userState, {
      ...user,
      userInfo: {
        ...(user.userInfo || {}),
        ...(newValue || {}),
      } as UserInfo,
    });
  },
});
export const userIdSyncState = selector({
  key: 'userIdSync',
  get({ get }) {
    const user = get(userModelDataState);
    // console.log('*** userIdSyncState **');
    // console.log(user.userId);
    // console.log('*** userIdSyncState **');
    return user.userId;
  },
});

export const clearUserData = selector({
  key: 'clearUserData',
  get({ get }) {
    return get(userModelDataState);
  },
  set({ set }) {
    set(userModelDataState, DEFAULT_USER);
  },
});
