import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import NotFoundPage from '../pages/404';
import SpinnerLoader from '../components/loading-block/loading-block.component';

const LazyWelcomePage = React.lazy(() => import('../pages/welcome/welcome.page'));
const LazyUserDetailsPage = React.lazy(() => import('../pages/user-details-form/user-details.page'));
const LazyCompleteReservationPage = React.lazy(() => import('../pages/complete-reservation/complete-reservation'));
const LazyVehiclesPage = React.lazy(() => import('../pages/vehicles/vehicles'));
const LazyReturnToDesktopPage = React.lazy(() => import('../pages/return-to-desktop/return-to-desktop.page'));

export const routes = {
  '/': {
    path: '/',
    label: 'Welcome',
  },
  'user-details': {
    path: '/user-details',
    label: 'User Details',
  },
  'complete-reservation': {
    path: '/complete-reservation',
    label: 'Complete Reservation',
  },
  vehicles: {
    path: '/vehicles',
    label: 'Vehicles',
  },
  'return-to-desktop-page': {
    path: '/return-to-desktop-page',
    label: 'Return To Desktop Page',
  },
};

const RootRoutes: React.FC = function () {
  return (
    <Suspense fallback={<SpinnerLoader />}>
      <Routes>
        <Route path={'/*'} key="not_found" element={<NotFoundPage />} />
        <Route path={'/'} key="welcome" element={<LazyWelcomePage />} />
        <Route path={'/verify'} key="verify" element={<LazyUserDetailsPage />} />
        <Route path={'/user-details'} key="user-details" element={<LazyUserDetailsPage />} />
        <Route path={'/complete-reservation'} key="complete-reservation" element={<LazyCompleteReservationPage />} />
        <Route path={'/vehicles'} key="vehicles" element={<LazyVehiclesPage />} />
        <Route path={'/return-to-desktop-page'} key="return-to-desktop-page" element={<LazyReturnToDesktopPage />} />
      </Routes>
    </Suspense>
  );
};

export default RootRoutes;
