import { checkUserEmail } from '../../api/check-user';
import { setUserCookie, setUserTokenCookie } from '../../store/user-management';
import { userState } from '../../store/user-model';
import { useCallback, useContext } from 'react';
import AppContext, { AppContextType } from '../../contexts/app.context';
import { useRecoilState } from 'recoil';
import { bindIdSignIn, bindIdSignUp } from './utils';
import { UserSignUpReturnValue } from './types';

export const useUserSignUpFlow = () => {
  const [, setUser] = useRecoilState(userState);
  const { setUserEmail, setLoginMethod } = useContext<AppContextType | null>(AppContext) as AppContextType;

  return useCallback(async (emailAddress: string): UserSignUpReturnValue => {
    const checkUserEmailRes = await checkUserEmail(emailAddress);
    const userDoesNotExist = checkUserEmailRes.status === 404;
    const bindIdUserAlreadyExists = !!checkUserEmailRes?.data?.result?.custom_app_data?.registeredAtBindId;

    const signAction = userDoesNotExist || !bindIdUserAlreadyExists ? 'register' : 'login';
    const recommendation = (await window.myRiskID?.triggerActionEvent(signAction))?.recommendation;
    const riskRecommendation = recommendation?.type;

    if (riskRecommendation && riskRecommendation === 'DENY') {
      return { status: 'threat-detected' };
    }

    const bindIdUserData = bindIdUserAlreadyExists
      ? await bindIdSignIn(emailAddress)
      : await bindIdSignUp(emailAddress);

    if (bindIdUserData) {
      setUserEmail(emailAddress);
      setLoginMethod('bindId');
      setUserCookie(bindIdUserData.userId);
      setUserTokenCookie(bindIdUserData.accessToken);

      setUser((oldState) => {
        return {
          ...oldState,
          isTransmitUser: true,
          isBindIdUser: true,
          userId: bindIdUserData.userId,
          userInfo: {
            isAuthenticated: true,
            email: emailAddress,
            customAppData: {
              ...checkUserEmailRes.data?.result?.custom_app_data,
              bindIdUserId: bindIdUserData.userId,
            },
            fullName: `${checkUserEmailRes.data?.result?.name?.first_name ?? ''} ${
              checkUserEmailRes.data?.result?.name?.last_name ?? ''
            }`.trim(),
            birthday: checkUserEmailRes.data?.result?.birthday,
            phoneNumber: checkUserEmailRes.data?.result?.custom_app_data?.phoneNumber,
            country: checkUserEmailRes.data?.result?.custom_app_data?.country,
            city: checkUserEmailRes.data?.result?.custom_app_data?.city,
          },
          bindId_userId: bindIdUserData.userId,
        };
      });

      return {
        status: 'success',
        userData: bindIdUserData,
      };
    } else {
      return {
        status: 'error',
      };
    }
  }, []);
};
