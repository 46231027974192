import { Image } from 'antd';
import React from 'react';
import Button from '../button/button.component';
import styled from 'styled-components';

const AuthenticateWithBindId: React.FC<{ callback: () => void }> = ({ callback }) => {
  return (
    <Button className={'xxl bindid'} type={'primary'} onClick={() => callback()} id="tip-auth-passwordless">
      <StyledFingerPrint src="/fingerprint.svg" />
      Continue
    </Button>
  );
};

const StyledFingerPrint = styled(Image)`
  right: 5px;
  position: relative;
`;

export default AuthenticateWithBindId;
